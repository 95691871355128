<template>
    <div class="animated fadeIn">
        <div class="wrapper">
            <CRow>
                <CCol lg="5">
                    <CCard>
                        <CCardHeader><font-awesome-icon icon="user"/> New User</CCardHeader>
                        <CCardBody>
                            <CForm @submit.prevent="registerUser">
                                <CRow>
                                    <CCol lg="8">
                                        <CInput 
                                            :lazy="false"
                                            :value.sync="$v.user.employee_number.$model"
                                            :isValid="checkIfValid('employee_number')"
                                            label="Employee Number" 
                                            type="text" 
                                            placeholder="50012342" 
                                            autocomplete="off"  
                                            v-model="user.employee_number"
                                        />
                                    </CCol>
                                    <CCol lg="8">
                                        <CInput 
                                            :lazy="false"
                                            :value.sync="$v.user.first_name.$model"
                                            :isValid="checkIfValid('first_name')"
                                            label="First name" 
                                            type="text" 
                                            placeholder="John" 
                                            autocomplete="off"  
                                            v-model="user.first_name"
                                            v-tocapitalize
                                        />
                                    </CCol>
                                    <CCol lg="4">
                                        <CInput 
                                            label="Middle Name" 
                                            type="text" 
                                            autocomplete="off"  
                                            v-model="user.middle_name"
                                            v-tocapitalize
                                        />
                                    </CCol>
                                    <CCol lg="8">
                                        <CInput 
                                            :lazy="false"
                                            :value.sync="$v.user.last_name.$model"
                                            :isValid="checkIfValid('last_name')"
                                            label="Last Name" 
                                            type="text" 
                                            placeholder="Doe" 
                                            autocomplete="off"  
                                            v-model="user.last_name"
                                            v-tocapitalize
                                        />
                                    </CCol>
                                    <CCol lg="4">
                                        <label> Suffix </label>
                                        <v-select 
                                            label="name"
                                            v-model="user.suffix"
                                            :options="suffix"
                                        />
                                    </CCol>
                                    <CCol lg="12">
                                        <CFormGroup
                                            wrapperClasses="input-group pt-2"
                                            description="ex. (999) 999-9999"
                                        >
                                            <template #prepend-content>
                                            +63
                                            </template>
                                            <template #label>
                                                Contact Number
                                            </template>
                                            <template #input>
                                            <masked-input
                                                type="tel"
                                                name="phone"
                                                class="form-control"
                                                v-model="user.contact_number"
                                                :mask="['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]"
                                                :guide="true"
                                                placeholderChar="#"
                                            />
                                            </template>
                                        </CFormGroup>
                                    </CCol>
                                    <CCol lg="12">
                                        <label>Department</label>
                                        <v-select 
                                            label="name"
                                            v-model="user.department"
                                            :options="departments_list"
                                            :class="!user.department ? 'has-error' : 'has-success'"
                                        />
                                    </CCol>
                                </CRow>
                                <CRow>
                                    <CCol lg="12">
                                        <br>
                                        <CCard border-color="info">
                                            <CCardHeader><font-awesome-icon icon="lock"/> User Credentials</CCardHeader>
                                            <CCardBody>
                                                <CRow>
                                                    <CCol lg="12">
                                                        <CInput 
                                                            :lazy="false"
                                                            :value.sync="$v.user.email_address.$model"
                                                            :isValid="checkIfValid('email_address')"
                                                            label="Email Address" 
                                                            type="Email" 
                                                            placeholder="johndoe@email.com" 
                                                            autocomplete="off"  
                                                            v-model="user.email_address"
                                                        />
                                                    </CCol>
                                                </CRow>
                                            </CCardBody>
                                        </CCard>

                                    </CCol>
                                </CRow>
                                <CRow>
                                    <CCol col="12" class="text-right">
                                        <CButton 
                                            :disabled="!isValid"
                                            type="submit" 
                                            shape="pill" 
                                            color="primary"
                                        >
                                            <font-awesome-icon icon="save"/> Save
                                        </CButton>
                                    </CCol>
                                </CRow>
                            </CForm>
                        </CCardBody>
                    </CCard>
                </CCol>
                <CCol lg="7">
                    <CCard>
                        <CCardHeader><font-awesome-icon icon="user-cog"/> User Permissions / Access </CCardHeader>
                        <CCardBody v-if="user.department">
                            <template v-if="department_has_super_user_access">
                                <CRow>
                                    <CCol lg="10">
                                       <strong>User Role</strong> &nbsp;&nbsp; 
                                       <a-popover title="NOTE:" placement="right">
                                            <template slot="content">
                                                <UserRoleHelp/>
                                            </template>
                                            <font-awesome-icon 
                                                style="color: #468dc8;" 
                                                icon="question-circle" 
                                            />
                                        </a-popover>
                                    </CCol>
                                    <CCol lg="10">
                                        <h4>
                                            <p-radio class="p-icon p-round p-jelly" name="role" color="warning" value="user" checked="checked" v-model="user.role">
                                                User
                                            </p-radio>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                            <p-radio class="p-icon p-round p-jelly" name="role" value="super-user" color="danger"  v-model="user.role">
                                                Super User &nbsp; 
                                            </p-radio>
                                        </h4>
                                    </CCol>
                                </CRow>
                                <br>
                                <br>
                            </template>
                            
                            <CRow>
                                <CCol :lg="user.role != 'super-user' ? '12' : '6'">
                                    <center>
                                        <h5 v-if="data_source_for_super_user.length > 0"><u>USER</u> access below.</h5>
                                    </center>
                                </CCol>
                                <template v-if="user.role == 'super-user'">
                                    <CCol lg="6">
                                    <center>
                                        <h5><u>SUPER USER</u> access below.</h5>
                                    </center>
                                    </CCol>
                                </template>
                            </CRow>
                            <CRow>
                                <CCol :lg="user.role != 'super-user' ? '12' : '6'">
                                    <CCard class="mb-0" v-for="(item, index) in data_source_for_user" v-bind:key="item.name">
                                        <CButton 
                                            block 
                                            color="link" 
                                            class="text-left shadow-none card-header" 
                                            @click="accordion = accordion === index ? false : index" 
                                        >
                                            <h5 class="m-0">{{item.name}}</h5>
                                        </CButton>
                                        <CCollapse :show="accordion === index">
                                            <CCardBody>
                                                <ejs-treeview 
                                                    :id="'tree_view_user_'+index"
                                                    :showCheckBox='true'
                                                    :fields="item.source"
                                                    :nodeClicked='nodeCheckedUserOnly' 
                                                   >
                                                </ejs-treeview>
                                            </CCardBody>
                                        </CCollapse>
                                    </CCard>
                                </CCol>
                                <template v-if="user.role == 'super-user'">
                                    <CCol lg="6">
                                        <CCard class="mb-0" v-for="(item, index) in data_source_for_super_user" v-bind:key="item.name">
                                            <CButton 
                                                block 
                                                color="link" 
                                                class="text-left shadow-none card-header" 
                                                @click="accordion_super_user = accordion_super_user === index ? false : index" 
                                            >
                                                <h5 class="m-0">{{item.name}}</h5>
                                            </CButton>
                                            <CCollapse :show="accordion_super_user === index">
                                                <CCardBody>
                                                    <ejs-treeview 
                                                        :id="'tree_view_super_user_'+index"
                                                        :showCheckBox='true'
                                                        :fields="item.source"
                                                        :nodeClicked='nodeCheckedSuperUserOnly' 
                                                       >
                                                    </ejs-treeview>
                                                </CCardBody>
                                            </CCollapse>
                                        </CCard>
                                    </CCol>
                                </template>
                            </CRow>
                        </CCardBody>
                    </CCard>
                </CCol>
            </CRow>
        </div>

    </div>
</template>
<script>
import { required, email, minLength } from "vuelidate/lib/validators"
import UserRoleHelp from './components/UserRoleHelp.vue'
export default {
    name: 'UserCreate',
    components: {
        UserRoleHelp,
    },
    data() {
        return {
            accordion: 0,
            accordion_super_user: 0,
            user: {
                employee_number: '',
                first_name: '',
                middle_name: null,
                last_name: '',
                suffix: '',
                contact_number: '',
                department: null,
                email_address: '',
                role:'user',
                permissions: [],
            },
            suffix: [
                {"name": "Jr."},
                {"name": "Sr."},
                {"name": "II"},
                {"name": "III"}
            ],
            departments_list: [],
            data_source:[],
            data_source_super_user:[],
            lookup_data_source:[],
        }
    },
    computed: {
        isValid () { return !this.$v.user.$invalid },
        isDirty () { return this.$v.user.$anyDirty },
        data_source_for_user: function () {
            return this.data_source.filter(x => x.source.dataSource.length > 0);
        },
        data_source_for_super_user: function () {
            return this.data_source_super_user.filter(x => x.source.dataSource.length > 0);
        },
        department_has_super_user_access: function () {
            let advance_module = this.data_source_for_super_user.filter(x => x.source.dataSource.length > 0);
            if(advance_module.length > 0) {
                return true;
            }
            return false;
        }

    },
    created() {
        this.validate();
        this.getDepartments()
        this.$emit('activeTab', 1);
    },
    validations: {
        user: {
            employee_number: { required,minLength: minLength(3) },
            first_name: { required, },
            last_name: { required, },
            department: { required, },
            role: { required, },
            email_address: { required, email },
        }
    },
    
    methods: {
        validate: function () {
            this.$v.$touch()
        },
        checkIfValid: function (fieldName) {
            const field = this.$v.user[fieldName]
            if (!field.$dirty) {
                return null
            } 
            return !(field.$invalid || field.$model === '')
        },
        resetForm: function () {
            return  {
                employee_number: "",
                first_name: "",
                middle_initial: null,
                last_name: "",
                suffix: "",
                department_id: "",
                department_name: "",
                contact_number: "",
                role: 'user',
                email_address: "",
                permissions: [],
            }
        },
        validateForm: function () // JS Validation
        {
            if(
                !this.user.employee_number ||
                !this.user.first_name ||
                !this.user.last_name ||
                !this.user.department ||
                !this.user.email_address
                ) return false;
            return true;
        },
        getDepartments: function () {
            axios.get('/departments/active-list', {validateStatus: () => true})
            .then(response => {
                if(response.status == 200) {
                    for(let i = 0; i < response.data.data.length; i++) {
                        let option = {
                            id: response.data.data[i].id,
                            name: response.data.data[i].name
                        }
                        this.departments_list.push(option)
                    }
                }
            })
        },
        registerUser: function() {
            let nodes = [];
            let check_nodes;
            let user_access_checker = false;
            let super_user_access_checker = false;

            for (let i = 0; i < this.data_source_for_user.length; i++) {
                let tree_object = document.getElementById('tree_view_user_'+i).ej2_instances[0];
                if(tree_object.checkedNodes.length > 0) {
                    user_access_checker = true;
                }
                nodes.push(tree_object.checkedNodes)
            }
            for (let i = 0; i < this.data_source_for_super_user.length; i++) {
                if(document.getElementById('tree_view_super_user_'+i)) {
                    let tree_object = document.getElementById('tree_view_super_user_'+i).ej2_instances[0];
                    if(tree_object.checkedNodes.length > 0) {
                        super_user_access_checker = true;
                    }
                    nodes.push(tree_object.checkedNodes)
                }
            }

            
            if(this.user.role == 'user' && !user_access_checker) {
                return this.$swal({
                    toast: true,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 3000,
                    icon: 'error',
                    title: 'Please select access for user.',
                    timerProgressBar: true,
                });
            }
            
            if(this.user.role == 'super-user' && !super_user_access_checker) {
                return this.$swal({
                    toast: true,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 3000,
                    icon: 'error',
                    title: 'Super user role is selected, please select access for super user.',
                    timerProgressBar: true,
                });
            }

            


            check_nodes = nodes.reduce(function(arr, e) { return arr.concat(e); })
            for(let i = 0; i < check_nodes.length; i++) {
                this.lookup_data_source.filter((g) => { 
                    if(g.id == check_nodes[i] && !g.hasChild) {
                        let row = {
                            group_id: g.gid,
                            module_id: g.mid,
                            module_access_id: g.maid,
                        }
                        this.user.permissions.push(row)
                    } 
                });
            }

            if(this.validateForm()) {
                // if(this.user.permissions <= 0) {
                //     return this.$swal({
                //         toast: true,
                //         position: 'top-right',
                //         showConfirmButton: false,
                //         timer: 3000,
                //         icon: 'error',
                //         title: 'Please add user permission / access.',
                //         timerProgressBar: true,
                //     });
                // }
                this.$swal({
                    icon: "warning",
                    title: "Are you sure?",
                    text: "You are trying to create new user.",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Yes",
                    showLoaderOnConfirm: true,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    preConfirm: () => { 
                        this.$Progress.start()
                        return this.insertNewUser().then(response => { 
                            return this.insertPermissionToNewUser(response).then(res => {
                                this.$swal({
                                    icon: "success",
                                    title: "Success!",
                                    html: `Avante account for <b>${this.user.first_name} ${this.user.last_name}</b> has been created. <br>Login credentials have been sent to the email address provided.`,
                                })
                                this.user = this.resetForm();
                                this.$v.$reset()
                                this.$v.$touch()
                                this.$Progress.finish();
                            })
                        })
                    },
                    allowOutsideClick: () => false
                    }).then((result) => {
                    if(!result.isConfirmed){
                        this.$swal('Cancelled!','','error');
                    }
                })
                return;
            } else {
                this.$swal({
                    toast: true,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 3000,
                    icon: 'error',
                    title: 'Please fill up required fields.',
                    timerProgressBar: true,
                });
                return;
            }
        },
        insertNewUser: function () {
            return new Promise((resolve, reject) => {
                axios.post('/users/store', this.user, {validateStatus: () => true})
                .then(response => {
                    if ( response.status == 200 ) {
                        resolve (response)
                    }
                })
            })
        },
        insertPermissionToNewUser: function (response) {
            return new Promise((resolve, reject) => {
                let data = {
                    user_id: response.data.data.id,
                    permissions: this.user.permissions
                }
                axios.post('/permissions/store', data, {validateStatus: () => true})
                .then(res => {
                    if(res.status == 200) {
                        resolve(res)
                    }
                })
            })
        },
        getDepartmentAccess: function () {
            this.$Progress.start()
            axios.get('/permissions/list/'+this.user.department.id, {validateStatus: () => true})
            .then(response => {
                if(response.status == 200) {
                    this.manipulatePermissions(response.data.data)
                    this.$Progress.finish();
                }
            })
        },
        manipulatePermissions:function (object) {
            let data = object.groups;
            this.lookup_data_source = [];
            this.data_source = [];
            this.data_source_super_user = [];
            let source;
            let source_super_user;
            let permission_rows;
            let permission_rows_super_user;
            let row_count = 0;
            if(data.length > 0) {
                for(let g = 0; g < data.length; g++) {
                    source = [];
                    source_super_user = [];
                    let m_row; // modules row
                    let ma_row; // modules access row
                    for(let m = 0; m < data[g].modules.length; m++) {
                        row_count++;
                        m_row = {
                            gid: data[g].id,
                            mid: data[g].modules[m].id,
                            tid: data[g].modules[m].id,
                            id: row_count,
                            name: data[g].modules[m].name,
                            hasChild: true,
                        }
                        if(data[g].modules[m].type == 'basic') {
                            source.push(m_row)
                            this.lookup_data_source.push(m_row)
                            for (let ma = 0; ma < data[g].modules[m].module_access.length; ma++) {
                                row_count++;
                                ma_row = {
                                    gid: data[g].id,
                                    mid: data[g].modules[m].id,
                                    tid: data[g].modules[m].module_access[ma].id,
                                    id: row_count,
                                    pid: m_row.id,
                                    name: data[g].modules[m].module_access[ma].name,
                                    maid: data[g].modules[m].module_access[ma].id,
                                }
                                source.push(ma_row)
                                this.lookup_data_source.push(ma_row)
                            }
                        } else {
                            source_super_user.push(m_row)
                            this.lookup_data_source.push(m_row)
                            for (let ma = 0; ma < data[g].modules[m].module_access.length; ma++) {
                                row_count++;
                                ma_row = {
                                    gid: data[g].id,
                                    mid: data[g].modules[m].id,
                                    tid: data[g].modules[m].module_access[ma].id,
                                    id: row_count,
                                    pid: m_row.id,
                                    name: data[g].modules[m].module_access[ma].name,
                                    maid: data[g].modules[m].module_access[ma].id,
                                }
                                source_super_user.push(ma_row)
                                this.lookup_data_source.push(ma_row)
                            }
                        }
                    }
                    let format = {
                        dataSource: source, 
                        id: 'id', 
                        parentID: 'pid', 
                        text: 'name', 
                        hasChildren: 'hasChild',
                        tableID: 'tid',
                        group_id: 'gid',
                        module_id: 'mid',
                        module_access_id: 'maid',
                    }
                    let format_super_user = {
                        dataSource: source_super_user, 
                        id: 'id', 
                        parentID: 'pid', 
                        text: 'name', 
                        hasChildren: 'hasChild',
                        tableID: 'tid',
                        group_id: 'gid',
                        module_id: 'mid',
                        module_access_id: 'maid',
                    }
                    permission_rows = {
                        id: data[g].id,
                        name: data[g].name,
                        source: format,
                    }
                    permission_rows_super_user = {
                        id: data[g].id,
                        name: data[g].name,
                        source: format_super_user,
                    }
                    this.data_source.push(permission_rows);
                    this.data_source_super_user.push(permission_rows_super_user);
                }

            }
        },
        nodeCheckedUserOnly: function(args, category) {
            let tree_view_name = args.node.getAttribute('id')
            let tree_view_id = tree_view_name.match(/\d+/)[0]
            let checkedNode = [args.node];
            let treeObj = document.getElementById('tree_view_user_'+tree_view_id).ej2_instances[0];
            if (args.event.target.classList.contains('e-fullrow') || args.event.key == "Enter") {
                var getNodeDetails = treeObj.getNode(args.node);
                if (getNodeDetails.isChecked == 'true') {
                    treeObj.uncheckAll(checkedNode);
                } else {
                    treeObj.checkAll(checkedNode);
                }
            }
        },
        nodeCheckedSuperUserOnly: function(args, category) {
            let tree_view_name = args.node.getAttribute('id')
            let tree_view_id = tree_view_name.match(/\d+/)[0]
            let checkedNode = [args.node];
            let treeObj = document.getElementById('tree_view_super_user_'+tree_view_id).ej2_instances[0];
            if (args.event.target.classList.contains('e-fullrow') || args.event.key == "Enter") {
                var getNodeDetails = treeObj.getNode(args.node);
                if (getNodeDetails.isChecked == 'true') {
                    treeObj.uncheckAll(checkedNode);
                } else {
                    treeObj.checkAll(checkedNode);
                }
            }
        }
    },
    watch: {
        'user.department': function () {
            if(this.user.department) {
                this.getDepartmentAccess()
            }
        },
    }
}
</script> 
